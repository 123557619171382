<template>
<div>
    <header class="restrain flex flex-col mx-auto my-12 px-6 py-1 sm:px-6 lg:px-8">
        <div class="relative flex-1 flex px-3 flex-col justify-center rounded-lg overflow-auto bg-cover bg-center items-center" style="background-image: url('/images/holding/trade.jpg')">
            <div class="gradient_smarta_orange"></div>
            <div class="relative">
                <div class="flex flex-col mx-auto items-center justify-center text-center md:flex-row  ">
                    <div class="p-8 xl:p-12 2xl:p-16">
                        <h1 class="text-smarta-blue-500 mb-6 lowercase text-3xl md:mt-6 xl:text-5xl">
                            Trade Smarta
                        </h1>
                        <p class="mb-6 font-normal text-white text-lg xl:text-xl">
                            Find a UK independent business that you'd like to buy from or sell to. To get your own business listed (at no cost) just create an account and then click Create Listing.
                        </p>
                        <p class="text-center mb-12">Trade Smarta is a directory that any UK small business can join. Smarta does not endorse any of the businesses listed.</p>
                        <div class="flex flex-col md:flex-row mt-8 gap-4">
                            <select class="w-full rounded-lg" v-model="selectedSector">
                                <option value="none" disabled selected>Type of business</option>
                                <option value="none">All</option>
                                <option v-for="sector in sectors" :value="sector.slug">{{ sector.name }}</option>
                            </select>
                            <select class="w-full rounded-lg" v-model="selectedCoverage">
                                <option value="none" disabled selected>Area they cover</option>
                                <option value="none">All</option>
                                <option v-for="coverage in coverages" :value="coverage.value">{{ coverage.name }}</option>
                            </select>
                            <select class="w-full rounded-lg" v-model="selectedConsumer">
                                <option value="none" disabled selected>Who they sell to</option>
                                <option v-for="consumer in consumers" :value="consumer.value">{{ consumer.name }}</option>
                            </select>
                            <!--
                            <button class="bg-smarta-pink text-white">CTA BUTTON</button>
                            -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <main class="mb-16 smart_bg--grey">
        <div class="flex">
            <div class=" flex flex-row flex-wrap w-full items-stretch restrain mx-auto mt-16 lg:w-10/12 2xl:w-8/12">
                <listing-card-component v-for="(listing, key) in listings" :key="key" :listing="listing"></listing-card-component>
            </div>
        </div>
        <div class="flex justify-end" style="width: 99%">
            <div v-for="pageIndex in pages" @click="changePage(pageIndex)" :class="['mx-1', 'underline', page == pageIndex ? 'text-smarta-pink-700' : 'text-smarta-pink-500', 'hover:text-smarta-pink-700']" style="cursor:pointer">{{ pageIndex }}</div>
        </div>
        <div class="flex justify-end mt-4 pb-4" style="width: 99%">
            <select class="w-24" @change="reloadListings" v-model="items">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
            </select>
        </div>
    </main>
</div>
</template>

<script>
import coverage from '../forms/json/coverage.json';
import businessConsumers from '../forms/json/businessAudienceType.json';

export default {
    data() {
        return {
            selectedSector: "none",
            selectedCoverage: "none",
            selectedConsumer: "none",
            consumers: businessConsumers,
            listings: [],
            pages: 1
        }
    },
    props: {
        sectors: {
            required: true,
            type: Array
        },
        coverages: {
            required: true,
            type: Object
        },
        page: {
            required: false,
            default: 1
        },
        items: {
            required: false,
            default: 25
        }
    },
    mounted() {
        this.reloadListings();
    },
    methods: {
        reloadListings() {
            let queryArray = [];
            if (this.selectedSector != "none") {
                queryArray.push("sector=" + this.selectedSector);
            }

            if (this.selectedCoverage != "none") {
                queryArray.push("coverage=" + this.selectedCoverage);
            }

            if (this.selectedConsumer != "none") {
                queryArray.push("consumer=" + this.selectedConsumer);
            }

            queryArray.push("items=" + this.items);
            queryArray.push("page=" + this.page);

            let queryString = "?" + queryArray.join('&');

            api.get('/listings/search' + queryString).then((resp) => {
                this.listings = resp.data.listings;
                this.pages = resp.data.pages;
            }).catch((resp) => {
                console.log("CATCH");
                console.log(resp);
            });
        },
        changePage(pageIndex) {
            this.page = pageIndex;
            this.reloadListings();
        }
    },
    watch: {
        selectedSector(value) {
            this.reloadListings();
        },
        selectedConsumer(value) {
            this.reloadListings();
        },
        selectedCoverage(value) {
            this.reloadListings();
        }
    }
}
</script>

<style scoped>

</style>
