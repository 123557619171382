<template>
    <div id="listing_gallery ">

        <!-- error dialog displays any potential errors -->
        <div v-if="errorDialog" class="bg-red-500 px-3 mx-3 py-2 text-white" v-model="errorDialog">
            {{errorText}}
        </div>

        <div class="flex flex-col px-3">

            <div class="relative w-1/2 md:w-1/3 px-3 my-6"  v-for="(file, int) in currentFiles" :key="int">
                <div class="horizontal flex flex-col my-2 ">

                    <div class="flex items-center">
                        <div class="flex-1">
                            <span class="font-body text-sm">{{ file.name }}</span>
                        </div>
                        <div class="ml-3 relative">
                            <b-tooltip label="Remove block" :delay="250">
                            <span class="cursor-pointer" @click="deleteCurrentFile(int,file)" title="Remove block">
                            <svg class="w-5 h-5 text-smarta-pink-500 hover:text-smarta-pink-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </span>
                            </b-tooltip>
                        </div>
                    </div>

                </div>
            </div>

            <div class="relative w-full px-3 my-3 border-b border-smarta-pink-500"  v-for="(file, key) in files" :key="key">

                <div class="horizontal flex flex-col my-2 ">

                    <div class="flex items-center">
                        <div class="flex-1">
                            <span class="font-body text-sm">{{ file.file.name }}</span>
                        </div>
                        <div class="ml-3 relative">
                            <b-tooltip label="Remove block" :delay="250">
                            <span class="cursor-pointer" @click="deleteFile(key)" title="Remove block">
                            <svg class="w-5 h-5 text-smarta-pink-500 hover:text-smarta-pink-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </span>
                            </b-tooltip>
                        </div>
                    </div>

                </div>

            </div>

        </div>


        <div v-if="showUploadBtn" class="w-1/2 md:w-1/3 px-3 my-6" >
            <div class="flex border-2 border-smarta-pink-500 justify-center items-center border-dashed cursor-pointer hover:text-smarta-blue-300 hover:border-smarta-pink-300">
                <label class="w-full justify-center flex file_select flex flew-row items-center select_button text-sm py-2 px-5 font-bold uppercase cursor-pointer transition duration-500 ease-in-out">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3M3 17V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"></path></svg>                        <span class="ml-2">Upload</span>
                    <input type="file" class="" multiple accept="application/pdf" @change="uploadFile"/>
                </label>
            </div>
        </div>

    </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.file_select > .select_button {

    color: white;
    border-radius: 0;

    text-align: center;
    font-weight: bold;
}

/* Don't forget to hide the original file input! */
.file_select > input[type="file"] {
    display: none;
}
</style>

<script>
export default {
    /** Prop value will allows us to retrieve currently set and populated data **/
    props: ['value'],
    data() {
        return {
            currentFiles: this.value,
            currentFilesToDelete: [],
            files: [],
            maxSize: 1024,
            maxUploads: 6,
            errorDialog: null,
            errorText: '',
            showUploadBtn: true
        }
    },
    created() {

        /** Firstly - let's count the length of the files array - new forms will be empty **/
        var length = this.files.length; // user defined length

        /** If max uploads limit is already hit - remove CTA for upload **/
        if(length >= this.maxUploads) {
            this.showUploadBtn = false;
        }

    },
    methods: {
        uploadFile(e) {

            let selectedFiles = e.target.files;

            //First lets do a check to see if the user has batch selected more than 9 files - if so return early and error
            if (selectedFiles.length > this.maxUploads){
                this.errorDialog = true
                this.errorText = 'Can only upload 6 files'
                return false;
            }

            for (let i = 0; i < selectedFiles.length; i++) {

                let file = {
                    src: URL.createObjectURL(selectedFiles[i]),
                    file: selectedFiles[i],
                }

                let size = file.file.size / this.maxSize / this.maxSize

                if (size>1) {
                    // check whether the size is greater than the size limit
                    this.errorDialog = true
                    this.errorText = 'Your file is too big! Please select an image under 1MB'
                }else if(!file.file.type.match('application/pdf')){
                    // check whether the file is actually an image type
                    this.errorDialog = true
                    this.errorText = 'Upload must be a PDF'
                }else if(this.files.length > (this.maxUploads - 1)){
                    // check whether current file exceeds our max limit not the minus 1 as we 0 index arrays!!
                    this.errorDialog = true
                    this.showUploadBtn = false;
                    this.errorText = 'Sorry, you have reach your maximum upload limit of 6 documents'
                }
                else{
                    this.errorDialog = false
                    this.files.push(file);
                }

            }

            this.$emit('updateFiles', this.files)
        },
        /** Delete the specific object from the array of locations - pass in the key of the specific object as passed
         * through in the click event **/
        deleteFile(key){

            this.$buefy.dialog.confirm({
                title: 'Confirm delete',
                message: 'Are you sure you want to delete this feature?',
                onConfirm: () => {
                    /** Find the corresponding key in the file array and remove it **/
                    this.files.splice(key,1);
                }
            })

        },
        /** Delete files for CURRENT files (FOR EDIT FORM ONLY) **/
        deleteCurrentFile(int, file){
            this.$buefy.dialog.confirm({
                title: 'Confirm delete',
                message: 'Are you sure you want to delete this file?',
                onConfirm: () => {
                    /** Find the corresponding key in the images array and remove it **/
                    this.currentFiles.splice(int,1);
                    this.currentFilesToDelete.push(file.name);
                    this.$emit('removeFiles', this.currentFilesToDelete)
                    flash('File removed', 'danger');
                }
            })
        }

    }
}
</script>
