<template>
    <div id="listing_locations">
            <!-- Here we want to loop through all the location within the location array
            and we want to ident each one via the key - this helps us identify the specific object-->

        <div class="flex flex-col md:flex-row md:flex-wrap">
            <div class="relative w-full md:w-1/2 px-3 mb-6" v-for="(location, key) in locations"  v-bind:key="key">
                    <div class="absolute top-0 right-0 mr-3">
                        <b-tooltip label="Remove location" :delay="250">
                            <span class="cursor-pointer" @click="deleteLocation(key)" title="Remove location">
                            <svg class="w-5 h-5 text-smarta-pink-500 hover:text-smarta-pink-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </span>
                        </b-tooltip>
                    </div>

                    <div class="horizontal flex flex-col my-4">
                        <label class="form_field_label" for="company_name">{{key+1}}. Location*</label>
                        <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Gateshead office..." id="name" v-model="locations[key].company_name" >
                        <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.locations[key].company_name.is_valid">{{ validations.locations[key].company_name.text }}</span>
                    </div>
                    <div class="horizontal flex flex-col mb-4">
                        <label class="form_field_label" for="address">Address*</label>
                        <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Abbey road..." id="address" v-model="locations[key].address">
                        <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.locations[key].address.is_valid">{{ validations.locations[key].address.text }}</span>
                    </div>
                    <div class="horizontal flex justify-between mb-4" >
                        <div class="w-1/2 mr-2">
                            <label for="location_type" class="form_field_label">Type</label>
                            <div class="select_wrapper select-default">
                                <!-- input block -->
                                <select v-model="locations[key].location_type" placeholder="Select type">
                                    <option value="" disabled>Select</option>
                                    <option
                                        v-for="option in allLocationTypes"
                                        :value="option.value"
                                        :key="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                                <!-- End block -->
                            </div>
                        </div>
                        <div class="w-1/2 ml-2" >
                            <div class="">
                                <!-- input block -->
                                <label class="form_field_label" for="city">City*</label>
                                <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Newcastle..." id="city" v-model="locations[key].city">
                                <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.locations[key].city.is_valid">{{ validations.locations[key].city.text }}</span>
                                <!-- End block -->
                            </div>
                        </div>
                    </div>
                    <div class="horizontal flex justify-between mb-4" >
                        <div class="w-1/2 mr-2">
                            <label for="province" class="form_field_label">County</label>
                            <div class="select_wrapper select-default">
                                <!-- input block -->
                                <select class="" v-model="locations[key].province" placeholder="Select county">
                                    <option value="" disabled>Select... </option>
                                    <option
                                        v-for="option in allRegions"
                                        :value="option.name"
                                        :key="option.name">
                                        {{ option.name }}
                                    </option>
                                </select>
                                <!-- End block -->
                            </div>
                        </div>
                        <div class="w-1/2 ml-2" >
                            <label for="country" class="form_field_label">Country</label>
                            <div class="select_wrapper select-default ">
                                <!-- input block -->
                                <select class="" v-model="locations[key].country" placeholder="Select Country">
                                    <option value="" disabled>Select...</option>
                                    <option
                                        v-for="option in allCountries"
                                        :value="option.name"
                                        :key="option.name">
                                        {{ option.name }}
                                    </option>
                                </select>
                                <!-- End block -->
                                </div>
                        </div>
                    </div>
                    <div class="horizontal flex flex-col mb-4">
                        <label class="form_field_label" for="postal_code">Postal Code*</label>
                        <input class="w-full form_field_input focus:outline-none" type="text" placeholder="NE1 1AA" id="postal_code" v-model="locations[key].postal_code" >
                        <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.locations[key].postal_code.is_valid">{{ validations.locations[key].postal_code.text }}</span>
                    </div>

                    <div class="horizontal flex flex-col mb-4">
                        <label class="form_field_label" for="postal_code">Show on profile</label>
                        <b-switch v-model="locations[key].location_private"
                                  true-value="1"
                                  false-value="0"
                                  >
                        </b-switch>
                    </div>

            </div>
        </div>
        <div class="button_group flex flex-row px-3 my-6">
            <!-- We have set the default button states in the data below, if true show, if false hide -->
            <button type="button"  class="button green" v-if="addLocationsButton" @click="addLocation">Add location</button>
            <button type="button" class="button pink" v-if="finishLocationsButton" @click="saveNewLocation">Save</button>
        </div>
    </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<script>

//Grab data for dropdown
import regions from '../json/regions.json';
import countries from '../json/countries.json';
import locationTypes from '../json/locationType.json';

export default {
    /** Prop value will allows us to retrieve currently set and populated data **/
    props: ['value'],
    /** Data sets our array for location objects + corresponding validation array, plus sets a couple of button
     * defaults **/
    data(){
        return {
            allCountries: countries,
            allRegions: regions,
            allLocationTypes: locationTypes,

            locations:this.value,
            validations: {
                locations: [
                ],
            },
            addLocationsButton: true,
            finishLocationsButton: false,
        }
    },
    created() {

        /** As we are validating each element we need to push validator object to the validiation array for the currently
         * submitted locations / data passed to the component via the values prop. Basically if we have two location
         * passed in, we need to count these and create two corresponding validator objects**/

        /** Firstly - let's count the legnth of the locations array - new forms will be empty **/
        var length = this.locations.length; // user defined length

        if ( length != null){

            /** Simple Iterator to push a new validation object for each location  **/
            for(var i = 0; i < length; i++) {
                this.validations.locations.push({
                    company_name: {
                        is_valid: true,
                        text: ''
                    },
                    address: {
                        is_valid: true,
                        text: ''
                    },
                    city: {
                        is_valid: true,
                        text: ''
                    },
                    postal_code: {
                        is_valid: true,
                        text: ''
                    }
                });
            }
        }else{

        }



    },
    mounted() {
        console.log( this.value);
        this.value[0].location_private = this.value[0].location_private ? "1" : "0";
    },
    methods : {
        /** Function that adds a new object block to the array (which we loop through in the template) and initialise
         * the values to empty strings **/
        addLocation(){
            /** Before we add a new block we ensure the validate method passes true for the current submission **/
            if( this.validateNewLocation() )
            {
                /** Push new empty input object to array **/
                this.locations.push({ company_name: '', address: '', location_type: '', apartment_suite: '', city: '', province: '', country: '',location_private:"0",postal_code: '', location_public: 'Yes',validated:false });
                /** Create a corresponding validation block, here we set an is_valid checker with a message string **/
                this.validations.locations.push({
                    company_name: {
                        is_valid: true,
                        text: ''
                    },
                    address: {
                        is_valid: true,
                        text: ''
                    },
                    city: {
                        is_valid: true,
                        text: ''
                    },
                    postal_code: {
                        is_valid: true,
                        text: ''
                    }
                });

                /** Update button display values **/
                this.finishLocationsButton = true;
                this.addLocationsButton = false;
            }

        },
        /** Delete the specific object from the array of locations - pass iin the key of the specific object as passed
         * through in the click event **/
        deleteLocation(key){

            this.$buefy.dialog.confirm({
                title: 'Confirm delete',
                message: 'Are you sure you want to delete this location?',
                onConfirm: () => {
                    /** Find the corresponding key in the location array and remove it **/
                    this.locations.splice(key,1);
                    /** Replicate this on the validation array aswell **/
                    this.validations.locations.splice(key,1);


                    if(this.locations.length === 0){
                        /** Update button display values **/
                        this.finishLocationsButton = false;
                        this.addLocationsButton = true;
                    }

                    //For sanity - lets ensure we emit to update the parent object - like we do on save
                    this.$emit('getLocationDetails', this.locations);

                    flash('Location removed', 'danger');
                }
            })

        },
        validateNewLocation()
        {
            /** Lets create a new valid form variable which we can set within the loop for each nested
             * location object **/
            let validNewLocationForm = true;

            /** Ensure all locations entered are valid - loop through current location array **/
            for( var index in this.locations )
            {
                /** Lets sanity check first to make sure object contains the property with this name of the argument **/
                if (this.locations.hasOwnProperty( index ))
                {
                    /** Ensure a name has been entered  - simple check on empty string **/
                    if( this.locations[index].company_name.trim() == '' )
                    {
                        /** If empty set form variable to false and insert error message **/
                        validNewLocationForm = false;
                        this.validations.locations[index].company_name.is_valid = false;
                        this.validations.locations[index].company_name.text = 'Please enter a name for this location';
                    }
                    else
                    {
                        /** Otherwise form in valid and we can return true (at bottom of loop) **/
                        this.validations.locations[index].company_name.is_valid = true;
                        this.validations.locations[index].company_name.text = '';
                    }
                }


                if (this.locations.hasOwnProperty( index ) ) {
                    /** Ensure an address has been entered */
                    if( this.locations[index].address.trim() == '' ){
                        validNewLocationForm = false;
                        this.validations.locations[index].address.is_valid = false;
                        this.validations.locations[index].address.text = 'Please enter an address';
                    }else{
                        this.validations.locations[index].address.is_valid = true;
                        this.validations.locations[index].address.text = '';
                    }
                }

                /** Ensure a city has been entered **/
                if( this.locations[index].city.trim() == '' ){
                    validNewLocationForm = false;
                    this.validations.locations[index].city.is_valid = false;
                    this.validations.locations[index].city.text = 'Please enter a city';
                }else{
                    this.validations.locations[index].city.is_valid = true;
                    this.validations.locations[index].city.text = '';
                }


                /** Ensure a postal code has been entered - regex on UK postcodes **/
                if( this.locations[index].postal_code.trim() == '' || !this.locations[index].postal_code.match(/\b(?:[A-Z][A-HJ-Y]?[0-9][0-9A-Z]? ?[0-9][A-Z]{2}|GIR ?0A{2})\b/i)){
                    validNewLocationForm = false;
                    this.validations.locations[index].postal_code.is_valid = false;
                    this.validations.locations[index].postal_code.text = 'Please enter a valid postal code';
                }else{
                    this.validations.locations[index].postal_code.is_valid = true;
                    this.validations.locations[index].postal_code.text = '';
                }

                if(validNewLocationForm)
                {
                    this.locations[index].validated = true;
                }
            }
            /** Once validation passes we return true **/
            return validNewLocationForm;
        },
        /** This is called on save - which emits the data to the parent form and resets the buttons **/
        saveNewLocation()
        {
            if( this.validateNewLocation() )
            {
                this.addLocationsButton = true;
                this.finishLocationsButton = false;
                /** This function is key - if we don't call it our parent form/component will not be able to access
                 * the data - VueJS Props and Emit - Props down from parent to child Emit up from Child to Parent **/
                this.$emit('getLocationDetails', this.locations);
                flash('Location added', 'success');
            }
        }
    }
}
</script>
