/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from "vue";
require('./bootstrap');

/** Import Vue **/
window.Vue = require('vue').default;

/** Import Beufy **/
import Buefy from 'buefy';
Vue.use(Buefy);

/** Import Algolia Instantsearch */
import InstantSearch from 'vue-instantsearch'
Vue.use(InstantSearch);

/** vuex store **/
import store from './store'

/** FontAwesome icons **/
import './icons.js';

/** For Flash data **/
window.events = new Vue();

window.flash = function (message, level = 'success') {
    window.events.$emit('flash', { message, level });
};

/** Register Global Vue Components here **/

// Navigation
Vue.component('navigation', () => import('./components/navigation/Navigation'))
Vue.component('navigation-dropdown', () => import('./components/navigation/Dropdown'))
Vue.component('navigation-item', () => import('./components/navigation/Item'))

// Newsletter
Vue.component('newsletter-signup', () => import('./components/NewsletterSignup'))
Vue.component('newsletter-signup-embed', () => import('./components/NewsletterSignupEmbed'))

// Auth Registration Form
Vue.component('registration-form', () => import('./components/auth/RegistrationForm'))

// Featured Items
Vue.component('featured-items-container', () => import('./components/featured-items/Container'))
Vue.component('featured-item', () => import('./components/featured-items/Item'))

// Home Slider
Vue.component('hero-image-slider', () => import('./components/home/HeroSlider'))

// Blog Posts
Vue.component('author-card', () => import('./components/posts/AuthorCard'))
Vue.component('latest-posts-slider', () => import('./components/posts/LatestSlider'))

// Courses
Vue.component('courses-index', () => import('./components/courses/Index'))
Vue.component('course-single-list', () => import('./components/courses/SingleList'))
Vue.component('course-single', () => import('./components/courses/Single'))
Vue.component('course-registration-bar', () => import('./components/courses/RegistrationBar'))
Vue.component('subjects-slider', () => import('./components/courses/SubjectsSlider'))
Vue.component('modules-slider', () => import('./components/courses/ModulesSlider'))
Vue.component('business-goals-slider', () => import('./components/courses/BusinessGoalsSlider'))
Vue.component('business-goals-card', () => import('./components/courses/BusinessGoalsCard'))
Vue.component('explore-subjects', () => import('./components/courses/ExploreSubjects'))

// Micro Courses
Vue.component('micro-course-single-module', () => import('./components/courses/micro/SingleModule'))
Vue.component('micro-course-quick-links', () => import('./components/courses/micro/QuickLinks'))
Vue.component('micro-course-confirmation-button', () => import('./components/courses/micro/ConfirmationButton'))
Vue.component('course-quiz', () => import('./components/courses/micro/CourseQuiz'))

// Algolia Search
Vue.component('algolia-search', () => import('./components/api/algolia/Search'))
Vue.component('news-search', () => import('./components/news/Search'))
Vue.component('global-search', () => import('./components/GlobalSearch.vue'))

// Algolia Modules
Vue.component('course-single-subject', () => import('./components/courses/algolia/SingleSubject'))
Vue.component('course-single-subject-card', () => import('./components/courses/algolia/SingleSubjectCard'))
Vue.component('course-single-module-card', () => import('./components/courses/algolia/SingleModuleCard'))
Vue.component('course-single-module', () => import('./components/courses/algolia/SingleModule'))

// Coaches
Vue.component('coaches-index', () => import('./components/coaches/Index'))
Vue.component('coach-single-list', () => import('./components/coaches/SingleList'))
Vue.component('coach-single-inline', () => import('./components/coaches/SingleInline'))

// Advice
Vue.component('advice-posts-block', () => import('./components/advice/Block'))
Vue.component('advice-post-item', () => import('./components/advice/Item'))

// Podcasts
Vue.component('podcast-posts-block', () => import('./components/podcasts/Block'))
Vue.component('podcast-post-item', () => import('./components/podcasts/Item'))

// Tutors
Vue.component('tutor-slider', () => import('./components/tutors/Slider'))
Vue.component('tutor-single-slide', () => import('./components/tutors/SingleSlide'))
Vue.component('tutor-single-inline', () => import('./components/tutors/SingleInline'))

// Products
Vue.component('products-index', () => import('./components/products/Index'))
Vue.component('product-single-domino', () => import('./components/products/SingleDomino'))
Vue.component('product-books-block', () => import('./components/products/books/Block'))

// Listings
Vue.component('listings-index', () => import('./components/listings/Index'))
Vue.component('listing-single-domino', () => import('./components/listings/SingleDomino'))

// Video modal
Vue.component('video-modal', () => import('./components/VideoModal'))
Vue.component('video-modal-trigger', () => import('./components/VideoModalTrigger'))

// Landing header video
Vue.component('landing-video-modal', () => import('./components/landingVideoModal'))

// Funding modal
Vue.component('funding-modal', () => import('./components/FundingModal'))

// Microcourse Modal
Vue.component('microcourse-modal', () => import('./components/courses/micro/ConfirmationModal'))

//Forms
Vue.component('statamic-form', () => import('./components/forms/StatamicForm'))
Vue.component('required', () => import('./components/forms/required'))
Vue.component('hubspot-form-embed', () => import('./components/HubspotFormEmbed'))

// Privacy/cookie Policy
Vue.component('cookiebot-button', () => import('./components/CookieBotModalButton.vue'));

//Listings form
Vue.component('listings-form', require('./components/forms/listingsForm.vue').default);
Vue.component('listings-form-edit', require('./components/forms/listingsFormEdit.vue').default);

// Listing index card
Vue.component('listing-card-component', require('./components/listings/cardComponent.vue').default);
// Listing component
Vue.component('listing-grid-component', require('./components/listings/filterGridComponent.vue').default);

//Products form
Vue.component('products-form', require('./components/forms/productsForm.vue').default);
Vue.component('products-form-edit', require('./components/forms/productsFormEdit.vue').default);

// Products index
Vue.component('product-card-component', require('./components/products/cardComponent.vue').default);
Vue.component('product-grid-component', require('./components/products/productGridComponent.vue').default);
Vue.component('product-filter-grid-component', require('./components/products/productFilterGridComponent.vue').default);

// Form Components
Vue.component('address-component', require('./components/forms/form_components/addressComponent.vue').default);
Vue.component('gallery-component', require('./components/forms/form_components/galleryComponent.vue').default);
Vue.component('image-upload-component', require('./components/forms/form_components/imageUploadComponent.vue').default);
Vue.component('wysiwyg-component', require('./components/forms/form_components/wysiwygComponent.vue').default);
Vue.component('accordion-component', require('./components/forms/form_components/accordionComponent.vue').default);
Vue.component('testimonial-component', require('./components/forms/form_components/testimonialComponent.vue').default);
Vue.component('trustpilot-component', require('./components/forms/form_components/trustpilotComponent.vue').default);
Vue.component('pricing-table-component', require('./components/forms/form_components/pricingTableComponent.vue').default);
Vue.component('feature-component', require('./components/forms/form_components/featureComponent.vue').default);
Vue.component('feature-alt-component', require('./components/forms/form_components/featureAltComponent.vue').default);
Vue.component('comparison-component', require('./components/forms/form_components/comparisonTable.vue').default);
Vue.component('download-component', require('./components/forms/form_components/downloadComponent.vue').default);

// Profile
Vue.component('profile-progress', require('./components/profile/profileProgress.vue').default);
Vue.component('delete-profile', require('./components/profile/deleteProfile.vue').default);
Vue.component('edit-profile', require('./components/profile/editProfile.vue').default);
Vue.component('edit-password', require('./components/profile/editPassword.vue').default);
Vue.component('edit-email', require('./components/profile/editEmail.vue').default);

// Dropdowns
Vue.component('dropdown-account', require('./components/DropdownAccount.vue').default);

// Flash
Vue.component('flash', require('./components/flash.vue').default);

// Tabs
Vue.component('tabs', require('./components/tabs.vue').default);
Vue.component('tab', require('./components/tab.vue').default);

// Accordion
Vue.component('accordion', require('./components/accordion.vue').default);

// Modules
Vue.component('course-modules', require('./components/courseModules.vue').default);

// Sliders
Vue.component('collections-slider', require('./components/CollectionsSlider').default);

// Faqs
Vue.component('faqs', require('./components/faqs.vue').default);

// Sticky Card
Vue.component('sticky-card', require('./components/products/stickyCard.vue').default);

// Slider
Vue.component('slider', require('./components/slider.vue').default);
Vue.component('box-slider', () => import('./components/BoxSlider'));

// Search components
Vue.component('search-component', require('./components/searchComponent.vue').default);

// Filter components
Vue.component('filters-component', require('./components/filters/filterComponent.vue').default);
Vue.component('filter-item-component', require('./components/filters/filterItemComponent.vue').default);

// Testimonial components
Vue.component('testimonial-display-component', require('./components/testimonialDisplayComponent.vue').default);

//Testimonial components
Vue.component('testimonial-slider', require('./components/testimonialSlider.vue').default);

// Admin
Vue.component('update-listing', require('./components/admin/updateListing.vue').default);
Vue.component('update-product', require('./components/admin/updateProduct.vue').default);

// Hubspot Form
Vue.component('learn-smarta-register', require('./components/hubspot/learnSmartRegister.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


/** Import modal and lets make it global via registering it within the main vue app below **/
import modal from './components/modal.vue';
import landingFormModal from './components/landingFormModal.vue';

/** New vue App init **/
const app = new Vue({
    el: '#app',
    store,
    data: {
        isModalVisible: false,
        isLandingFormModalVisible: false
    },
    components: {
        modal,
        landingFormModal
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
            console.log('OPEN: trigger this function');
        },
        close() {
            console.log('CLOSE: emitted to this function');
            this.isModalVisible = false;
        },
        showLandingFormModal() {
            this.isLandingFormModalVisible = true;
            console.log('OPEN: trigger this function');
        },
        closeLandingFormModal() {
            console.log('CLOSE: emitted to this function');
            this.isLandingFormModalVisible = false;
        }
    },
});


/**
 * Define you app variable globally - Needed so we can ref things like modal outside of components
 * https://stackoverflow.com/questions/42782610/uncaught-referenceerror-vm-is-not-defined-vue-js-2-0
 * Allows us to do things like <button onclick='vm.$refs.foo.cardModal();'>Button</button>
 */
global.vm = app;

/**
 * Smooth scroll functionality for anchor links (animates the scroll
 * rather than a sudden jump in the page)
 */
$('.js-anchor-link').click(function(e){
    e.preventDefault();
    var target = $($(this).attr('href'));
    if(target.length){
        var scrollTo = target.offset().top;
        $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
    }
});

/**
 * Global function to scroll to specific elements we want to target on a page
 */
window.scrollToElement = (id) => {
    const el = document.getElementById(id);
    const navHeight = 120;
    const ctaHeight = 48;
    const padding = 32;

    if (el) {
        window.scroll({
            top: el.offsetTop - (navHeight + ctaHeight + padding),
            left: 0,
            behavior: 'smooth'
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const hash = window.location.hash;

    if (hash) {
        scrollToElement(hash.slice(1))
    }
})

document.querySelectorAll('[data-scroll-to]').forEach((elem) => {
    elem.addEventListener('click', (e) => {
        e.preventDefault();
        window.scrollToElement(elem.getAttribute('data-scroll-to'));
    });
});

// Asset Gallery

var $overlay = $('<div id="asset_gallery_overlay" class="asset_gallery_overlay"></div>');
var $image = $('<img class="overlay_img">');
var $prevButton = $('<div id="asset_gallery_prev_btn" class="asset_gallery_prev_btn"><img class="actions" src="/images/chevron-left.svg"></div>');
var $nextButton = $('<div id="asset_gallery_next_btn" class="asset_gallery_next_btn"><img class="actions" src="/images/chevron-right.svg"></div>');
var $exitButton = $('<div id="asset_gallery_exit_btn" class="asset_gallery_exit_btn"><img class="actions" src="/images/cross.svg"></div>');

// Add overlay
$overlay.append($image).prepend($prevButton).append($nextButton).append($exitButton);
$("#asset_gallery").append($overlay);

// Hide overlay on default
$overlay.hide();

// When an image is clicked
$(".asset_image_overlay").click(function(event) {
    // Prevents default behavior
    event.preventDefault();
    // Adds href attribute to variable
    var imageLocation = $(this).prev().attr("href");
    // Add the image src to $image
    $image.attr("src", imageLocation);
    // Fade in the overlay
    $overlay.fadeIn("slow");
});

// When the overlay is clicked
$overlay.click(function() {
    // Fade out the overlay
    $(this).fadeOut("slow");
});

// When next button is clicked
$nextButton.click(function(event) {
    // Hide the current image
    $("#asset_gallery_overlay .overlay_img").hide();
    // Overlay image location
    var $currentImgSrc = $("#asset_gallery_overlay .overlay_img").attr("src");
    // Image with matching location of the overlay image
    var $currentImg = $('#asset_image_gallery img[src="' + $currentImgSrc + '"]');
    // Finds the next image
    var $nextImg = $($currentImg.closest(".image_container").next().find(".asset_image"));
    // All of the images in the gallery
    var $images = $("#asset_image_gallery .asset_image");
    // If there is a next image
    if ($nextImg.length > 0) {
        // Fade in the next image
        $("#asset_gallery_overlay .overlay_img").attr("src", $nextImg.attr("src")).fadeIn(800);
    } else {
        // Otherwise fade in the first image
        $("#asset_gallery_overlay .overlay_img").attr("src", $($images[0]).attr("src")).fadeIn(800);
    }
    // Prevents overlay from being hidden
    event.stopPropagation();
});


// When previous button is clicked
$prevButton.click(function(event) {
    // Hide the current image
    $("#asset_gallery_overlay .overlay_img").hide();
    // Overlay image location
    var $currentImgSrc = $("#asset_gallery_overlay .overlay_img").attr("src");
    // Image with matching location of the overlay image
    var $currentImg = $('#asset_image_gallery img[src="' + $currentImgSrc + '"]');
    // Finds the next image
    var $nextImg = $($currentImg.closest(".image_container").prev().find(".asset_image"));
    // Fade in the next image
    $("#asset_gallery_overlay .overlay_img").attr("src", $nextImg.attr("src")).fadeIn(800);
    // Prevents overlay from being hidden
    event.stopPropagation();
});

// When the exit button is clicked
$exitButton.click(function() {
    // Fade out the overlay
    $("#asset_gallery_overlay").fadeOut("slow");
});
